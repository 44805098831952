<app-breadcrumbs></app-breadcrumbs>
<section class="order-section">
    <div class="product-list-wrap" style="margin-top: 55px;">
    
    
        <!-- <div class="allorders-filter-row flex">
    
            <div class="aofrr-search">
                <input type="text" placeholder="Search" class="form-input search" (keydown.enter)="search($event.target.value)">
            </div>
            
            <div class="aofr-rht flex">
    
                <div *ngIf="filterDataId.length >0" class="search-right flex" (click)="exportEarnings()" [title]=" filterDataId.length === 0 ? 'Bulk export' : 'Export'">
                    <button class="button-reg secondary"> <img title="Export" alt="Export" src="assets/imgs/export.svg" style="width:16px"> Export</button>
                    <span class="multi-spin" *ngIf="(paymentSandbox.exportEarningLoading$ | async) || (paymentSandbox.MultipleEarningExportLoading$ | async)"><i class="fas fa-spinner fa-spin fa-2x" style="color: blue;"></i></span>
                </div>
                <div *ngIf="filterDataId.length === 0" class="search-right flex" (click)="exportEarnings()" [title]=" filterDataId.length > 0 ? 'Bulk export' : 'Export'">
                    <button class="button-reg secondary"> <img title="Export" alt="Export" src="assets/imgs/export.svg" style="width:16px">Export All</button>
                    <span class="multi-spin" *ngIf="(paymentSandbox.exportEarningLoading$ | async) || (paymentSandbox.MultipleEarningExportLoading$ | async)"><i class="fas fa-spinner fa-spin fa-2x" style="color: blue;"></i></span>
                </div> -->
                
                <!-- <div class="dropdown">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Filters <img src="assets/imgs/header-icons/arrow-down.svg" alt="">
                    </button>
                    <div class="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <form action="" class="flex" >
                            <div class="input-group ig-date">
                                <p>From Date</p>
                                <input type="text" readonly  class="date"  autocomplete="off" (dateSelect)="onDateSelect($event)" name="dp" id="cal-in" ngbDatepicker #f="ngbDatepicker" formControlName="fromDate" [minDate]="" [maxDate]="minPickerDate" (click)="f.toggle()">
                            </div>
                            <div class="input-group ig-date">
                                <p>To Date</p>
                                <input type="text" readonly class="date" name="dp" autocomplete="off" id="cal-in" ngbDatepicker #d="ngbDatepicker" formControlName="toDate" [ngClass]="{'disabled': dateError !== ''}" [minDate]="filterForm.value.fromDate" [maxDate]="minPickerDate" (click)="setMinValue(d)">
                            </div>
                             <div class="error" style="color: red;position:absolute;margin-left:505px;margin-bottom:-19px;">
                                {{dateError}}
                            </div> 
                            <div class="error" *ngIf="isRequired" style="color: red;position:absolute;margin-left:505px;margin-bottom:-19px;">
                                To Date is required
                            </div> 
                            <button class="button-reg primary">Apply Filters</button>
                            <a class="reset-filter">Reset</a>
                        </form>
                    </div>
                </div> -->
    
                
<!--     
            </div>
        </div> -->
    
        <!-- <div class="loading" *ngIf="shipmentList.length">
            <app-loader></app-loader>
        </div> -->
        <!-- <div class="no-data" *ngIf="(paymentSandbox.categoryList$ | async)?.length === 0 && (paymentSandbox.categoryListLoaded$ | async)">
            <span>No Earnings found</span> </div> -->
        <div class="table-responsive ">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <!-- <th></th> -->
                        <th style="text-align: center;">Shipment #</th>
                        <th style="text-align: center;">Buyer</th>
                        <th style="text-align: center;">Amount($)</th>
                        <th style="text-align: center;">Shipping Charge($)</th>
                        <th style="text-align: center;">Weight</th>
                        <th style="text-align: center;">Dimensions</th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center;">Tracking</th>
                        <th style="text-align: center;">Action</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let shipment of shipmentList">
                        <tr [ngClass]="shipment.id ? '': 'active'">
                            <td>
                                <span class="drop-down" [ngClass]="shipment.id ? '': 'active'" (click)="active(shipment.id)">
                                    <img src="../../../../../../assets/images/sp-down.svg" (click)="shipment.id = !shipment.id" />
                                </span>
                            </td>
                            <!-- <td>
                                <span class="check-action">
                                    <input type="checkbox" [disabled]="shipment.trackingNo && shipment.trackingNo > 0" (click)="checkUncheckShipment($event, shipment.id, shipment.customerId)">
                                </span>
                            </td> -->
                            <td>
                                <span class="id-num">
                                    {{shipment.shipment_id}}
                                </span>
                            </td>
                            <td>
                                {{shipment.username}}
                            </td>
                            <td>
                                {{array?.currencySymbolLeft}}{{shipment.amount * 1 + shipment.shippingAmount * 1 | number}}{{array?.currencySymbolRight}}
                            </td>
                            <td>
                                {{array?.currencySymbolLeft}}{{shipment.shippingAmount * 1}}{{array?.currencySymbolRight}}
                            </td>
                            <td>
                                {{shipment.weight}} oz
                            </td>
                            <td>
                                {{shipment?.dimension}} in
                            </td>
                            <td>
                                {{shipment?.orderStatusName}}
                            </td>
                            <td style="text-align: center;">
                                <span class="id-num" *ngIf="shipment.trackingUrl">
                                    <a href="{{shipment.trackingUrl}}" target="_blank">
                                        <!-- {{shipment.trackingUrl}} -->
                                        <div title="{{shipment.trackingUrl}}" *ngIf="shipment.trackingUrl" class="description mb16">{{shipment.trackingUrl.substring(0,70)}}<span *ngIf="shipment.trackingUrl.length > 70">...</span></div>
                                    </a>
                                </span>
                                <span *ngIf="!shipment.trackingUrl" >
                                    Not available
                                </span>
                            </td>
                            <td>
                                <span *ngIf="shipment.trackingNo && shipment.trackingNo > 0" class="primary-btn" style="cursor: pointer;" (click)="printLabel(shipment.shipment_id)">
                                    <img src="../../../../../../../assets/images/print-icon.svg" alt="">
                                    Print Label
                                </span>
                                <span *ngIf="!shipment.trackingNo && shipment.orderStatusName === 'Packing In Progress'" class="primary-btn" style="cursor: pointer;" (click)="generateLabel(shipment.shipment_id, shipment.customerId, shipment.orderId)">
                                    Create Label
                                </span>                
                            </td>
                            <td>
                                <span class="edit" *ngIf="!(shipment.trackingNo && shipment.trackingNo > 0)" (click)="editShipment(shipment)">
                                    Edit
                                </span>
                            </td>
                        </tr>
                        <td id="#{{shipment.id}}" [hidden]="shipment.id" colspan="12" *ngIf="shipment.shipmentProducts && shipment.shipmentProducts.length > 0">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Order Id #</th>
                                        <th>Product</th>
                                        <th>Description</th>
                                        <!-- <th>Status</th> -->
                                        <th>Weight</th>
                                        <th>Price ($)</th>
                                        <th>Product Order status</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let shipmentOrder of shipment.shipmentProducts">
                                        <td></td>
                                        <td>
                                            <span class="check-action">
                                                <input type="checkbox" [disabled]="shipment.trackingNo && shipment.trackingNo > 0" (click)="checkUncheckOrders($event, shipment.id, shipmentOrder.orderId)">
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {{shipmentOrder.vendorOrderId}}
                                            </span>
                                        </td>
                                        <td>
                                            {{shipmentOrder.productName}}
                                        </td>
                                        <td>
                                            {{shipmentOrder.description}}
                                        </td>
                                        <!-- <td>
                                            {{shipmentOrder.order_status}}
                                        </td> -->
                                        <td>
                                            {{shipmentOrder.weight}} oz
                                        </td>
                                        <td>
                                            {{shipmentOrder.amount * 1 | number}}
                                        </td>
                                        <td>
                                            {{shipmentOrder.orderStatusName}}
                                        </td>
                                    </tr>
                                </tbody>
                           </table> 
                       </td>
                   </ng-container>
               </tbody>
           </table>
        </div>
    
    
    </div>
    <div class="showing-results-wrapper">
        <div class="showing-results flex items-center flex-sb">
            <div class="left-part">
                Showing 1-10 of {{this.totalCount}} results
            </div>
        </div>
        <!-- end of container -->
    
        <!-- pagination -->
        <div class="pager">
            <app-pager (pageChange)="pageChange($event)" [pageSize]="limit" [currentPage]="currentPage" [counts]="this.totalCount"></app-pager>
        </div>
    </div>
    <!-- </div> -->
    </section>